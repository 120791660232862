import request from '../../../../utils/request';
import BasePage from '../../../../components/page_base';

export default {
  name: 'condition_classification',
  extends: BasePage,
  data() {
    return {
      formConfig: {},
      modalConfig: {
        // title: '新增',
        visible: false,
        type: '',
      },
      row: [],
      cellClickConfig: {
        functionCode: 'condition_edit',
        queryUrl: '/mdm/mdmPriceConditionGroupController/query',
      },
    };
  },

  methods: {
    /**
     * @override
     * 提交表单
     * @param formData
     */
    onSubmit(formData) {
      console.log(this.row);
      if (formData) {
        const params = formData;
        const formItem = this.$refs.modalForm.getRule('conditionFieldList').options;

        const newList = [];
        formItem.forEach((v) => {
          if (params.conditionFieldList.includes(v.dictCode)) {
            newList.push({
              fieldCode: v.dictCode,
              fieldName: v.dictValue,
            });
          }
        });
        params.conditionFieldList = newList;

        let url = '/mdm/mdmPriceConditionGroupController/save';

        if (this.modalConfig.config.code === 'edit') {
          url = '/mdm/mdmPriceConditionGroupController/update';
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });

            this.closeModal();
            this.getList();
          }
        });
      }
    },
    formatData(data) {
      return data;
    },
    /**
     * @override
     * 可根据字段field 设置列属性
     * @param col
     * @returns {any}
     */
    setColumn(col) {
      console.log(col);
      const coll = col;
      if (coll.col === 'feeBudgetCode') {
        Object.assign(col, {
          on: {
            change() {},
          },
        });
      } else if (coll.field === 'createDate') {
        coll.formatter = ({ row }) => `${row.createDate || ''} ${row.createDateSecond || ''}`;
      } else if (coll.field === 'updateDate') {
        coll.formatter = ({ row }) => `${row.updateDate || ''} ${row.updateDateSecond || ''}`;
      }
      return { ...coll };
    },
    setRowBtn(btn) {
      // if(btn.code ===){

      // }
      return { ...btn };
    },
    /**
     * @override
     * 可根据字段field 设置列属性
     * @returns {any}
     * @param field
     */
    setFormField(field) {
      // if (this.modalConfig.config.code === 'edit') {
      //   this.modalConfig.title = '编辑';
      // } else if (this.modalConfig.config.code === 'add') {
      //   this.modalConfig.title = '新增';
      // } else if (this.modalConfig.config.code === 'view') {
      //   this.modalConfig.title = '查看';
      // }
      if (field.field === 'conditionGroupCode') {
        Object.assign(field, {
          props: {
            disabled: this.modalConfig.config.buttonCode !== 'add',
          },
          validate: [
            {
              pattern: '^[a-zA-Z0-9]*$',
              message: '条件字段分类编码仅支持输入英文和数字',
              trigger: 'blur',
            }, {
              required: true,
              message: '请输入条件字段分类编码',
              trigger: 'blur',
            },
          ],
        });
      }
      if (field.field === 'conditionFieldList') {
        Object.assign(field, {
          dictCode: 'price_condition',
          value: [],
          props: {
            ...field.props,
            valueKey: 'fieldCode',
          },
        });
      }
      return { ...field };
    },
    /// 打开弹窗之前的方法
    async beforeModalClick({ val, row }, rule) {
      this.formConfig = { ...this.formConfig, code: val.buttonCode };
      if (val.buttonCode === 'edit') {
        this.modalConfig.title = '编辑';
        this.modalConfig.width = '600px';
        this.modalConfig.height = '260px';
        const res = await request.get('/mdm/mdmPriceConditionGroupController/editCheck', { id: row.id });
        if (res.success) {
          if (res.result.editCheck) {
            return { val, row, rule };
          }
          this.$message.error(res.result.message);
          return false;
        }
      }
      if (val.buttonCode === 'add') {
        this.modalConfig.width = '600px';
        this.modalConfig.height = '260px';
        this.modalConfig.title = '新增';
      }
      if (val.buttonCode === 'view') {
        this.modalConfig.title = '查看';
        this.modalConfig.width = '600px';
        this.modalConfig.height = '260px';
      }
      this.row = row;
      return { val, row, rule };
    },

    formLoadComplete() {
      setTimeout(() => {
        this.$refs.modalForm.fApi.setValue({
          conditionFieldList: this.$refs.modalForm.fApi.getRule('conditionFieldList').value.map((v) => v.fieldCode),
        });
      }, 100);
    },
    addForm() {
      // this.disabled(true, ['conditionGroupDesc']);
      console.log(this.configs.rule);
    },
  },
  watch: {
    // 'modalConfig.visible': {
    //   deep: true,
    //   handler(visible) {
    //     console.log(visible);
    //     if (visible) {
    //       this.$nextTick(() => {
    //         // {multiple: true}
    //         this.setFormFieldDisabled(true, ['conditionGroupDesc']);
    //       });
    //     }
    //   },
    // },
  },
};
